var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"breadcrumb-wrapper"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"16"}}),_vm._v(" Dashboard ")],1),_c('b-breadcrumb-item',{attrs:{"active":true}},[_vm._v(" "+_vm._s(_vm.pageType)+" "+_vm._s(_vm.resourceName)+" ")])],1)],1)]),_c('b-col',{staticClass:"mb-2 text-right",attrs:{"md":"12"}},[(!_vm.readonlyStatus)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","to":{ name: 'view-profile' }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"FileTextIcon","size":"16"}}),_vm._v(" View "+_vm._s(_vm.resourceName)+" ")],1):_vm._e()],1)],1),_c('b-card',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"pl-1 border-left-primary border-left-3"},[_vm._v(" "+_vm._s(_vm.pageType)+" "+_vm._s(_vm.resourceName)+" ")]),(!_vm.userData)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"m-1",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Loading User Form"}})],1):_vm._e(),(_vm.userData)?_c('div',[_c('validation-observer',{ref:"resourceForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateUser.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"Name","readonly":_vm.readonlyStatus},model:{value:(_vm.formClinicData.name),callback:function ($$v) {_vm.$set(_vm.formClinicData, "name", $$v)},expression:"formClinicData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"placeholder":"Phone","readonly":_vm.readonlyStatus},model:{value:(_vm.formClinicData.phone),callback:function ($$v) {_vm.$set(_vm.formClinicData, "phone", $$v)},expression:"formClinicData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(!_vm.readonlyStatus)?_c('b-col',{staticClass:"mt-1 text-right",attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.submitLoading}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || !dirty}},[_vm._v(" Save Changes ")])],1)],1):_vm._e()],1)],1)]}}],null,false,107136972)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }